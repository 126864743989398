import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useForm } from "../../../hooks/useForm"
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmptyValue } from "../../../helpers/methods";
import { useCallback } from "react";
import { Grid } from "@mui/material";
import SipocNodeForm from "../../form/SipocNodeForm";
import useProcessService from "../../../services/processService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

function SipocNodeDialog(props) {
    const { t } = useTranslation();

    const snackbarAlert = useSnackbarAlert();


    const requiredFields = [
        "name",
    ];

    const {
        getSipocNode,
        createSipocNode,
        updateSipocNode
    } = useProcessService()

    const createSipocNodeFn = useAsyncFn(createSipocNode);
    const updateSipocNodeFn = useAsyncFn(updateSipocNode);

    const {
        formValue,
        setFormValue,
        onChange
    } = useForm({ 'sipocNode': props.sipocNodeKind })

    const isFormValid = () => {
        return requiredFields.every(
            (fieldName) => !isEmptyValue(formValue[fieldName])
        );
    };

    const sipocNodeData = useAsync(() => {
        if (props.sinoId) {
            return getSipocNode(props.sinoId).then((res) => {


                setFormValue({
                    id: res.id,
                    sipocNode: res.sino_kind,
                    name: res.sino_name
                }
                )
            })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.occurred_error_during_get_data")
                    );
                });
        }
        return Promise.resolve(formValue)
    }, [props.sinoId])


    const onPrepareData = (data) => {
        return {
            'process': props.processId,
            'sino_x': props.positionX,
            'sino_y': props.positionY,
            'sino_name': data.name,
            'sino_kind': props.sipocNodeKind
        }
    }

    const onSubmit = useCallback(() => {
        if (isEmptyValue(props.sinoId)) {
            createSipocNodeFn
                .execute(onPrepareData(formValue))
                .then((res) => {
                    snackbarAlert.openSuccessSnackbarAlert(
                        t("snackbar_alert.sipoc_node_added")
                    );
                    if (props.onRefetch) {
                        props.onRefetch();
                    }
                    props.onClose();
                })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.occurred_error_during_adding_sipoc_node")
                    );
                });
        } else {
            updateSipocNodeFn
                .execute(props.sinoId, { 'sino_name': formValue.name })
                .then((res) => {
                    snackbarAlert.openSuccessSnackbarAlert(
                        t("snackbar_alert.sipoc_node_updated")
                    );
                    if (props.onRefetch) {
                        props.onRefetch();
                    }
                    props.onClose();
                })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.occurred_error_during_updating_sipoc_node")
                    );
                });
        }

    }, [formValue])



    return (
        <BasicDialog
            open={props.open}
            titleAlign="center"
            contentAlign="center"
            title={props.sinoId ? t("dialog.add_node_dialog.edit_sipoc_element") : t("dialog.add_node_dialog.add_sipoc_element")}
            fullScreen={false}
            showCustomFooter={false}
            showDialogActions={true}
            onClose={props.onClose}
            maxWidth={"sm"}
            showTopCloseButton={true}
        >
            <Grid container spacing={1}>

                <LoaderWrapper showLoader={sipocNodeData.loading}>
                    <Grid item xs={12}>
                        <SipocNodeForm
                            formValue={formValue}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            loading={props.isLoading}
                            disabled={!isFormValid()}
                            onClick={() => onSubmit(formValue)}
                        >
                            {props.sinoId ? t("universal.save") : t("universal.create")}
                        </LoadingButton>
                    </Grid>
                </LoaderWrapper>
            </Grid>
        </BasicDialog >
    )
}

SipocNodeDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    sipocNodeKind: PropTypes.string,
    positionX: PropTypes.number,
    positionY: PropTypes.number
};

SipocNodeDialog.defaultProps = {
    open: false,
    positionX: 0,
    positionY: 0,
};

export default SipocNodeDialog