
import { useCallback, useEffect, useRef, useState } from "react";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper/DefaultPageWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import { useParams } from "react-router-dom";
import useProjectService from "../../services/projectService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import {
  ReactFlow,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  useReactFlow
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import SipocNode from "../../components/flow/nodes/SipocNode/SipocNode";
import {  SIPOC_INPUT,  SIPOC_SUPPLIER } from "../../helpers/constants";
import SipocEdge from "../../components/flow/edges/SipocEdge/SipocEdge";
import SipocTitleNode from "../../components/flow/nodes/SipocTitleNode/SipocTitleNode";
import useSipocStructure from "../../hooks/useSipocStrcuture";
import SipocButtonNode from "../../components/flow/nodes/SipocButtonNode";
import useSipocDialog from "../../hooks/useSipocDialog";
import useProcessService from "../../services/processService";
import useSipocMapService from "../../services/sipocMapService";
import SipocNodeDialog from "../../components/dialog/SipocNodeDialog";


export default function SipocPage(props) {
  const { pageName } = props;
  const [projectTitle, setProjectTitle] = useState()
  const { projectId, processId } = useParams();
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [structureWidth, setStructureWidth] = useState(600)


const {
  setDataFromBackend,
  prepareSipocNodeToBackend
} = useSipocMapService();

  const nodesRef = useRef([]);

  const {
    getProcessSipocMapData,
    updateSipocNode,
    deleteSipocNode,
    createSipocEdge,
    deleteSipocEdge
  } = useProcessService();

  const processSipocMapData = useAsync( () => getProcessSipocMapData(processId), [processId]);
  const updateSipocNodeFn = useAsyncFn(updateSipocNode)
  const deleteSipocNodeFn = useAsyncFn(deleteSipocNode)
  const createSipocEdgeFn = useAsyncFn(createSipocEdge)
  const deleteSipocEdgeFn =useAsyncFn(deleteSipocEdge)

  const [
    openSipocDialog,
    handleOpenSipocNodeDialog,
    handleCloseSipocNodeDialog,
    handleOpenSipocNodeDialogWithId,
    sipocNodeKind,
    positionX,
    sipocNodeId
  ] = useSipocDialog();

  const onAddNode = (nodeType) => {
    handleOpenSipocNodeDialog(nodeType, getMaxWidthOfSipocKind(nodeType))
  }

  const edgeTypes = {
    sipocEdge: SipocEdge,
  };

  const nodeTypes = {
    sipocNode: SipocNode,
    titleNode: SipocTitleNode,
    buttonNode: SipocButtonNode
  };


  const {
    getProjectAccess
  } = useProjectService();


  const [
    structureNodes
  ] = useSipocStructure(
    structureWidth,
    onAddNode)

  const [nodes, setNodes, onNodesChange] = useNodesState([...structureNodes]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);



  const getMaxWidthOfSipocGroups = () => {
    if (nodesRef.current.filter((node) => node.type === 'sipocNode').length === 0) {
      return 100
    }
    return nodesRef.current.filter((node) => node.type === 'sipocNode').reduce((n1, n2) => n1.position.x > n2.position.x ? n1 : n2).position.x + 250
  }

  const getMaxWidthOfSipocKind = (sipocKind) => {
    if (nodesRef.current.filter((node) => node.parentId === sipocKind).filter((node) => node.type === 'sipocNode').length === 0) {
      return 10
    }
    return nodesRef.current.filter((node) => node.parentId === sipocKind).filter((node) => node.type === 'sipocNode').reduce((n1, n2) => n1.position.x > n2.position.x ? n1 : n2).position.x + 200
  }

  const onRemoveNode = useCallback((nodeId) => {
    onDeleteNode(nodeId)
  }, []);


  const onEditNode = useCallback((nodeId) => {
    handleOpenSipocNodeDialogWithId(nodeId)
  }, []);

  const onNodesChangeLocal = useCallback((changes) => {
    onNodesChange(changes)
    if (changes[0].type === "position" && changes[0].dragging === false) {
      onUpdateNode(prepareSipocNodeToBackend(changes[0]))
    } 
  }, [onNodesChange])



  useEffect(() => {
    
    if (structureWidth !== getMaxWidthOfSipocGroups()) {
      setStructureWidth(getMaxWidthOfSipocGroups())
    }
    let tempNodes = nodes.filter((node) => node.type !== 'group').filter((node) => node.type !== 'titleNode').filter((node) => node.type !== 'buttonNode');
    setNodes([...structureNodes, ...tempNodes])
    nodesRef.current = [...structureNodes, ...tempNodes]
  }, [nodesRef.current.length, structureNodes]) 

 
  const onRemoveEdge = useCallback((id, data) => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
    if (data?.isNew !== true) {
      onDeleteEdge(id)
    }
  }, [])

  const customSipocNodes = nodes.map((node) => ({
    ...node,
    data: { ...node.data, onRemove: onRemoveNode, onEdit : onEditNode },
  }));

  const customSipocEdges = edges.map((edge) => ({
    ...edge,
    type: 'sipocEdge',
    data: { ...edge.data, onRemove: onRemoveEdge },
  }));


  const onConnect = useCallback((connection) => {
    setEdges((eds) => onAddEdgeLocal(connection, eds));
  }, []);

  const onAddEdgeLocal = useCallback((connection, eds) => {
    if ((isSupplierNode(connection.source) && isInputNode(connection.target)) || (!isSupplierNode(connection.source) && !isInputNode(connection.target))) {
      connection = { ...connection, process : processId} 
      onCreateEdge(connection) 
      return addEdge(connection, eds)
    }
    return eds
  }, [nodes])


  const isSupplierNode = (sourceId) => {
    return nodesRef.current.find((inp) => inp.id === sourceId && inp.parentId === SIPOC_SUPPLIER) !== undefined
  }

  const isInputNode = (targetId) => {
    return nodesRef.current.find((inp) => inp.id === targetId && inp.parentId === SIPOC_INPUT) !== undefined
  }

  

  const { fitView } = useReactFlow();

  const projectAccessData = useAsync(() => {
    if (projectId) {
      return getProjectAccess(projectId)
    }
    return Promise.resolve({})
  }, [projectId])


  useEffect(() => {
    if (projectAccessData.loading) return
    if (projectAccessData.value) {
      setProjectTitle(projectAccessData.value.project_name)
    }
  }, [projectAccessData.loading])

  useEffect(() => {
    if (processSipocMapData.loading) return
    if (processSipocMapData.value) { 
      let tempNodes = setDataFromBackend(processSipocMapData.value.nodes)
      setNodes([...structureNodes, ...tempNodes])
      setEdges(processSipocMapData.value.edges)
      nodesRef.current = [...structureNodes, ...tempNodes]
      setStructureWidth(getMaxWidthOfSipocGroups())
    }
  }, [processSipocMapData.loading])



  const onUpdateNode = useCallback((node) => {
    updateSipocNodeFn
    .execute(node.id, node)
    .then((res) => {
      snackbarAlert.openSuccessSnackbarAlert(
        t("snackbar_alert.sipoc_node_updated")
      );
      processSipocMapData.refetch()
    })
    .catch((error) => {
      processSipocMapData.refetch()
      snackbarAlert.openErrorSnackbarAlert(
        t("snackbar_alert.occurred_error_during_updating_sipoc_node")
      );
    });
}, [])

const onDeleteNode = useCallback((nodeId) => {
  deleteSipocNodeFn 
  .execute(nodeId)
  .then((res) => {
    snackbarAlert.openSuccessSnackbarAlert(
      t("snackbar_alert.sipoc_node_deleted")
    );
    processSipocMapData.refetch()
  })
  .catch((error) => {
    processSipocMapData.refetch()
    snackbarAlert.openErrorSnackbarAlert(
      t("snackbar_alert.occurred_error_during_deleting_sipoc_node")
    );
  });
}, [])

const onDeleteEdge = useCallback((edgeId) => {
  deleteSipocEdgeFn 
  .execute(edgeId)
  .then((res) => {
    snackbarAlert.openSuccessSnackbarAlert(
      t("snackbar_alert.sipoc_edge_deleted")
    );
    processSipocMapData.refetch()
  })
  .catch((error) => {
    processSipocMapData.refetch()
    snackbarAlert.openErrorSnackbarAlert(
      t("snackbar_alert.occurred_error_during_deleting_sipoc_edge")
    );
  });
}, [])


const onCreateEdge = useCallback((edgeData) => {
  createSipocEdgeFn
  .execute(edgeData)
  .then((res) => {
    snackbarAlert.openSuccessSnackbarAlert(
      t("snackbar_alert.sipoc_edge_added")
    );
    processSipocMapData.refetch()
  })
  .catch((error) => {
    processSipocMapData.refetch()
    snackbarAlert.openErrorSnackbarAlert(
      t("snackbar_alert.occurred_error_during_adding_edge_node")
    );
  });
}, [])



  if (projectAccessData.loading) return (<LoaderWrapper showLoader={true} />)



  return (
    <NavigationDrawer pageName={pageName} projectAccessData={projectAccessData?.value}>
      <DefaultPageWrapper titleKey={"sipoc"} projectTitle={projectTitle}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          columnSpacing={1}
          rowSpacing={1}
          style={{ marginTop: "2px" }}
        >
          <Grid item xs={12} style={{ height: 800 }}>
            <ReactFlow
              nodes={customSipocNodes}
              edges={customSipocEdges}
              onNodesChange={onNodesChangeLocal}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              edgeTypes={edgeTypes}
              nodeTypes={nodeTypes} 
              defaultZoom={0.9}
              // fitView
              panOnDrag={false}
              panOnScroll={false}
              zoomOnScroll={false}
              panOnScrollSpeed={0}  
            >
              <Controls 
                showInteractive={false}/>
            </ReactFlow>
          </Grid>
        </Grid>
        {openSipocDialog &&
          <SipocNodeDialog
            open={openSipocDialog}
            onClose={handleCloseSipocNodeDialog}
            sipocNodeKind={sipocNodeKind}
            positionX={positionX}
            sinoId = {sipocNodeId}
            positionY={20}
            processId = {processId}
            onRefetch =  {processSipocMapData.refetch}
          />

        }
      </DefaultPageWrapper>
    </NavigationDrawer >
  );
}
