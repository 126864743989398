import React, { useState } from "react";
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import styled from '@emotion/styled';
import PersonCard from "../../other/PersonCard";
import useProjectService from "../../../services/projectService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import useDialog from "../../../hooks/useDialog";
import Button from "@mui/material/Button";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import AddMemberDialog from "../../dialog/AddMemberDialog/AddMemberDialog";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import useUserService from "../../../services/userService";
import { useCallback } from "react";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import PropTypes from "prop-types";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const Container = styled.div`
  display: flex;
`;

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 150px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 25px;
  align-members: center;
`;

const TaskColumnStyles = styled.div`
  margin: 4px;
  display: flex;
  width: 100%;
  min-height: 75vh;
`;

const role_name = styled.span`
  color: #10957d;
  background: rgba(16, 149, 125, 0.15);
  padding: 2px 10px;
  border-radius: 5px;
  align-self: flex-start;
  align-members: center;
`;



const TeamDragableTable = (props) => {
  const { t } = useTranslation();

  const snackbarAlert = useSnackbarAlert();
  const [
    openAddMemberDialog,
    onOpenAddMemberDialog,
    handleCloseAddMemberDialog,
  ] = useDialog();


  const {
    getProjectTeam
  } = useProjectService();

  const {
    updateTeamMember,
    deleteTeamMember
  } = useUserService();

  const projectTeamData = useAsync(() => getProjectTeam(props.projectId), [props.projectId])

  const updateTeamMemberFn = useAsyncFn(updateTeamMember);
  const deleteTeamMemberFn = useAsyncFn(deleteTeamMember)

  const [teamMembersByRole, setTeamMembersByRole] = useState()
  const [teamId, setTeamId] = useState()

  React.useEffect(() => {
    if (projectTeamData.loading) {
      return;
    }
    if (projectTeamData.value) {
      let teams = projectTeamData.value.teams
      if (teams.length > 0) {
        setTeamMembersByRole(teams[0].members_by_roles)
        setTeamId(teams[0].id)
      }
    }

  }, [projectTeamData.loading]);

  const onUpdateData =  useCallback((memberId, data) => {
    updateTeamMemberFn
    .execute(memberId,data)
    .then((res) => {
      projectTeamData.refetch()
      snackbarAlert.openSuccessSnackbarAlert(
        t("snackbar_alert.team_role_member_updated")
      );
    })
    .catch((error) => {
      snackbarAlert.openErrorSnackbarAlert(
        t("snackbar_alert.occurred_error_during_updating_team_role_member")
      );
    });
},[])

const onDeleteData =  useCallback((memberId) => {
  deleteTeamMemberFn
  .execute(memberId)
  .then((res) => {
    snackbarAlert.openSuccessSnackbarAlert(
      t("snackbar_alert.team_role_member_deleted")
    );
  })
  .catch((error) => {
    snackbarAlert.openErrorSnackbarAlert(
      t("snackbar_alert.occurred_error_during_deleting_team_role_member")
    );
  });
  onRefetchData()
},[])



  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourcemembers = [...sourceColumn.members];
      const destmembers = [...destColumn.members];
      const [removed] = sourcemembers.splice(source.index, 1);
      let data = {
        'role' : destColumn.id,
        'member' : removed.id,
        'team' : teamId
      }
   
      onUpdateData(removed.id, data)
      destmembers.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          members: sourcemembers,
        },
        [destination.droppableId]: {
          ...destColumn,
          members: destmembers,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedmembers = [...column.members];
      const [removed] = copiedmembers.splice(source.index, 1);

      copiedmembers.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          members: copiedmembers,
        },
      });
    }
  };

  const onRefetchData = () =>{
    projectTeamData.refetch()
    props.projectAccessDataRefetch()
  }

  const contextMenuActions  = [
    {
      label: t("table.team_dragable_table.remove_member"),
      callback: (memberId) => onDeleteData(memberId),
      icon: <HighlightOffIcon fontSize="small" />,
    },
  ];
  
  return (

    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      columnSpacing={1}
      rowSpacing={1}
      style={{ marginTop: "2px" }}
    >
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
          onClick={onOpenAddMemberDialog}
        >
          {t("pages.team_page.new_team_member")}
        </Button>
      </Grid>
    
      <Grid item xs={12}>
        {teamMembersByRole &&
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, teamMembersByRole, setTeamMembersByRole)}
          >
            <Container style={{ overflowY: "auto" }}>
              <TaskColumnStyles>
                {Object.entries(teamMembersByRole).map(([columnId, column], index) => {
                  return (
                    <Droppable key={columnId} droppableId={columnId}>
                      {(provided, snapshot) => (
                        <TaskList
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <role_name>{t(`table.team_dragable_table.${column?.role_name}`)}</role_name>
                          {column?.members?.map((item, index) => (
                 
                            <PersonCard 
                            key={item} 
                            item={item}
                             index={index} 
                             showContextMenuActions ={!props.readOnly}
                             contextMenuActions= {contextMenuActions}
                            />
                          ))}
                          {provided.placeholder}
                        </TaskList>
                      )}
                    </Droppable>
                  );
                })}
              </TaskColumnStyles>
            </Container>
          </DragDropContext>}
      </Grid>
      {
        openAddMemberDialog &&
        <AddMemberDialog
          open={openAddMemberDialog}
          onClose={handleCloseAddMemberDialog}
          onRefetch = {onRefetchData}
          teamId = {teamId}
          companyName = {projectTeamData.value.company_short_name}
        />

      }
    </Grid>
  );
};
TeamDragableTable.propTypes = {
  readOnly: PropTypes.bool,

};

TeamDragableTable.defaultProps = {
readOnly: false
};

export default TeamDragableTable;
