import { useState } from "react";
import useDialog from "./useDialog";

const useSipocDialog = () => {
  
  const [openDialog, handleOpenDialog, handleCloseDialog] = useDialog();
  const [sipocNodeKind, setSipocNodeKind] = useState();
  const [positionX, setPositionX] = useState();
  const [sipocNodeId, setSipocNodeId]  = useState();

  const handleOpenSipocNodeDialog = (nodeKind, positionX, nodeId = undefined) => {
    setSipocNodeKind(nodeKind);
    setPositionX(positionX);
    setSipocNodeId(nodeId);
    handleOpenDialog();
  };

  const handleOpenSipocNodeDialogWithId = (nodeId) => {
    setSipocNodeId(nodeId);
    handleOpenDialog(); 
  };

  const handleCloseSipocNodeDialog = () => {
    setSipocNodeKind(null);
    setPositionX(null);
    setSipocNodeId(null)
    handleCloseDialog();
  };

  return [openDialog, handleOpenSipocNodeDialog, handleCloseSipocNodeDialog,  handleOpenSipocNodeDialogWithId, sipocNodeKind, positionX, sipocNodeId];
};

export default useSipocDialog;
