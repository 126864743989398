import PropTypes from "prop-types";
import InfoIcon from '@mui/icons-material/Info';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import SelectField from "../../field/SelectField/SelectField"
import { IconButton } from "@mui/material";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import useLogout from "../../../hooks/useLogout";

export default function ProjectDashboardBar(props) {
  const { t } = useTranslation();
  const {
    onLogout
  } = useLogout();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      columnSpacing={1}
      rowSpacing={2}
      style={props.style}
    >
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          color="error"
          onClick={props.onBuySubscription}
        >
          {t("bar.project_dashboard_bar.subscription")}
        </Button>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
          onClick={props.onCreateNewProject}
        >
          {t("bar.project_dashboard_bar.new_project")}
        </Button>
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectField
          name="project"
          label={t("bar.project_dashboard_bar.select_project")}
          value={props.selectedProjectId}
          options={props.projects}
          optionLabel={"project_name"}
          optionKey={'id'}
          size="small"
          addNewValue={false}
          onChange={props.onChange}
          variant="outlined"
          onClear={props.onClear}
        />
      </Grid>
      <Grid item xs={12} sm={0.2} style={{margin :"2px"}}>
        <IconButton
          disabled={props.selectedProjectId ? false : true}
          onClick={props.onOpenStepper}
        >
          <InfoIcon color={props.selectedProjectId ? "primary" : "secondary"} fontSize="large" />
        </IconButton>
      </Grid>
      <Grid item xs={12} sm={0.2}>
        <IconButton
          onClick={onLogout}
          colotr = {"primary"}
        >
          <PowerSettingsNewIcon  fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

ProjectDashboardBar.propTypes = {
  style: PropTypes.object,
  onBuySubscription: PropTypes.func,
  onCreateNewProject: PropTypes.func,
  selectedProjectId: PropTypes.string,
  projects: PropTypes.array,
  onChange: PropTypes.func
};

ProjectDashboardBar.defaultProps = {

};
