import React, { useCallback, useState } from 'react';
import {
    BaseEdge,
    EdgeLabelRenderer,
    getSmoothStepPath,
    EdgeText,
} from '@xyflow/react';
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

import './sipocEdge.css';
import { isEmptyValue } from '../../../../helpers/methods';

export default function SipocEdge({
    id,
    data,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    selected
}) {

    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const onEditClick = useCallback(() => {
        data.onEdit(id)
    }, [id, data]);

    const onRemoveClick = useCallback(() => {
        data.onRemove(id)
    }, [id, data]);


    return (
        <>

            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style}

            />
            {!isEmptyValue(data?.label) &&
            <EdgeText
                x={labelX}
                y={labelY}
                label={data?.label}
                labelStyle={{ fontSize: 12 }}
            />}
            <EdgeLabelRenderer>

                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan"
                >
                    {
                        selected &&
                        <>

                        {data?.type === 'map' &&
                            <IconButton
                                color="var(--secondary)"
                                onClick={onEditClick}
                                sx={{ padding: "5px" }}
                            >
                                <EditIcon fontSize="medium" />
                            </IconButton>}
                            <IconButton
                                color="var(--secondary)"
                                onClick={onRemoveClick}
                                sx={{ padding: "5px" }}
                            >
                                <CancelIcon fontSize="medium" />
                            </IconButton>
                        </>
                    }

                </div>
            </EdgeLabelRenderer>
        </>
    );
}
