import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useForm } from "../../../hooks/useForm"
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmptyValue } from "../../../helpers/methods";
import { useCallback } from "react";
import { Grid } from "@mui/material";
import useProcessService from "../../../services/processService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

function AddEdgeLabelDialog(props) {
    const { t } = useTranslation();

    const snackbarAlert = useSnackbarAlert();

    const {
        getOperationEdge,
        updateOperationEdge
    } = useProcessService()

    const updateEdgeNodeFn = useAsyncFn(updateOperationEdge);

    const {
        formValue,
        setFormValue,
        onChange
    } = useForm({ id: props.edgeId })


    const operationEdgeData = useAsync(() => {
        if (props.edgeId) {
            return getOperationEdge(props.edgeId).then((res) => {
                setFormValue(res)
            })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.occurred_error_during_get_data")
                    );
                });
        }
        return Promise.resolve(formValue)
    }, [props.edgeId])


    const onSubmit = useCallback(() => {
        updateEdgeNodeFn
            .execute(props.edgeId, formValue)
            .then((res) => {
                snackbarAlert.openSuccessSnackbarAlert(
                    t("snackbar_alert.edge_updated")
                );
                if (props.onRefetch) {
                    props.onRefetch();
                }
                props.onClose();
            })
            .catch((error) => {
                snackbarAlert.openErrorSnackbarAlert(
                    t("snackbar_alert.occurred_error_during_updating_edge")
                );
            });
    }, [formValue])



    return (
        <BasicDialog
            open={props.open}
            titleAlign="center"
            contentAlign="center"
            title={t("dialog.add_edge_label_dialog.add_edge_label")}
            fullScreen={false}
            showCustomFooter={false}
            showDialogActions={true}
            onClose={props.onClose}
            maxWidth={"sm"}
            showTopCloseButton={true}
        >
            <Grid container spacing={1}>
                <LoaderWrapper showLoader= {operationEdgeData.loading}>
                <Grid item xs={12}>
                    <TextFieldFormControl
                        name="opsrel_text"
                        label={t(`dialog.add_edge_label_dialog.edge_label`)}
                        value={formValue?.opsrel_text}
                        onChange={onChange}

                    />
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        loading={props.isLoading}
                        onClick={onSubmit}
                    >
                        {t("universal.save")}
                    </LoadingButton>
                </Grid>
                </LoaderWrapper>
            </Grid>
        </BasicDialog >
    )
}

AddEdgeLabelDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    edgeId: PropTypes.string,
    onRefetch: PropTypes.func
};

AddEdgeLabelDialog.defaultProps = {
    open: false,
};

export default AddEdgeLabelDialog