export const ScrumpionAppApiUrl = window._env_.SCRUMPION_API_URL;
export const scrumpionUrl = 'https://scrumpion.com/'
export const scrumpionLinkedinUrl = 'https://www.linkedin.com/company/scrumpion/'

export const loginUrl = "/users/auth/login/";
export const logoutUrl = "/users/auth/logout/";
export const refreshTokenUrl = "/users/auth/refresh/";
export const registrationUrl = "/users/registration/";
export const companySelectListUrl = "users/company/select_list/";
export const clientSelectListUrl = "users/client/select_list/";
export const userSelectListUrl = "users/select_list/";
export const userTeamMembersSelectListUrl = (teamId) => `users/select_list/${teamId}/team_members/`;
export const roleTeamSelectListUrl = "users/role_team/select_list/";
export const memberCreateUrl = "users/member/";
export const memberDetailsUrl  = (memberId) =>  `users/member/${memberId}/`;


export const addressKindSelectListUrl = "addresses/address_kinds/";

export const projectUrl = "projects/";
export const projectDataUrl = (projectId) => `projects/${projectId}/`;
export const projectAccessDataUrl = (projectId) => `projects/${projectId}/access/`;
export const projectFullDataUrl = (projectId) => `projects/${projectId}/full_data/`;
export const projectTeamDataUrl = (projectId) => `projects/${projectId}/team/`;
export const projectSelectListUrl = "projects/select_list/";
export const processesByProjectListUrl = (projectId) => `projects/project_process/${projectId}/list/`;
export const projectProcessDataUrl = (projectProcessId) => `projects/project_process/${projectProcessId}/`; 
 
export const processUrl = "processes/";
export const processDataUrl = (processId) => `processes/${processId}/`;
export const processMapDataUrl = (processId) => `processes/${processId}/map/`;
export const mapNodesRenumerateUrl = (processId) => `processes/${processId}/map/renumerate/`;
export const projectProcessOperationUrl = `processes/project_process/operations/`;
export const operationUrl = `processes/operation/`;
export const operationDetailsUrl = (operationId)  => `processes/operation/${operationId}/`
export const operationNodeDataUrl = (nodeId) => `processes/operation/${nodeId}/`
export const processOperationUrl = `processes/operations/`;
export const processOpsProviderUrl = "processes/ops_provider/";
export const providerDataUrl = (providerId) => `processes/ops_provider/${providerId}/`
export const operationEdgeUrl = `processes/operation_related/`;
export const mapEdgeDataUrl = (edgeId) => `processes/operation_related/${edgeId}/`

export const linkUrl = "links/link/";

export const productBacklogsUrl = "backlogs/product_backlog/"
export const productBacklogDetailUrl = (productBacklogId) => `${productBacklogsUrl}${productBacklogId}/`;
export const userStoryUrl = "backlogs/user_story/"
export const userStoryDetailUrl = (userStoryId) => `${userStoryUrl}${userStoryId}/`;
export const priorityParamUrl = "backlogs/priority_param/"
export const processSipocMapDataUrl = (processId) => `processes/${processId}/sipoc_map/`;
export const createSipocNodeUrl  = "processes/sipoc_node/";
export const sipocNodeDataUrl = (nodeId) => `processes/sipoc_node/${nodeId}/`
export const createSipocEdgeUrl  = "processes/sipoc/";
export const sipocEdgeDataUrl = (edgeId) => `processes/sipoc/${edgeId}/`
