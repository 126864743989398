import { SIPOC_OUTPUT, SIPOC_SUPPLIER } from "../helpers/constants"
import { useTranslation } from "react-i18next";


export default function useSipocMapService() {
  const { t } = useTranslation();
 
  const getNodeType = (sipocKind) =>{
    if ([SIPOC_SUPPLIER, SIPOC_OUTPUT].includes(sipocKind))
      return 'source'
    return 'target'
  }

  const setDataFromBackend = (data) => {
    let tempNodes = []
    for (let n of data){
      tempNodes.push({
        id : n.id,
        data : {label : n.sino_name, type: "sipoc_shape", sipocType : getNodeType(n.sino_kind)},
        measured: { width: 150, height: 40 },
        position: { x: n.sino_x, y: n.sino_y },
        className: 'light',
        parentId: n.sino_kind,
        extent: 'parent',
        type: 'sipocNode'
      })
    }
    return tempNodes
  }

  const prepareSipocNodeToBackend = (node) =>{
    return {
      id : node.id,
      sino_x : node.position.x,
      sino_y : node.position.y
     }
  }

  const prepareOperationNodeToBackend = (node, provider) =>{
    return {
      id : node.id,
      ops_coordinate_x : node.position.x,
      ops_coordinate_y : node.position.y,
      ops_color:  node.data.buttonCollor,
      ops_coordinate_y : provider?  calcualteDetaYCoordinate(node.position.y, provider): node.position.y,
      ops_provider : provider? provider.id : null
     }
  }

  const prepareStartEndNodeToBackend = (node, provider, positionX, positionY) =>{
    return{
      ops_coordinate_x : positionX,
      ops_coordinate_y : calcualteDetaYCoordinate(positionY, provider),
      ops_text : node.data.type === "start_shape"? "start" : "stop",
      shape : node.data.type,
      ops_color:  node.data.buttonCollor,
      ops_provider : provider.id,
      ops_dimension_h : 50, 
      ops_dimension_w : 75
    }
  }

  const generateProvidersGroups = (providersList, structureWidth, handleOpenProviderDialog, addProviderButtonNodeYCoordinate = 100, onEditProvider, onRemoveProvider ) => {
    let groupProviders = []
    let i = 1;

    for (let provider of providersList) {

      groupProviders.push(
        {
          id: `${i}`,
          draggable: false,
          position: { x: 150, y: provider.provider_coordinate_y_min },
          className: 'light',
          style: { backgroundColor: 'rgb(204, 213, 215, 1)', borderRadius: 0, width: 200, height: provider.provider_dimension_h, zIndex : -1},
          type: 'group',
        }, 
        {
          id: provider.id,
          data: {label: provider.provider_name, type: "map", y_coordinates : {y_min : provider.provider_coordinate_y_min , y_max : provider.provider_coordinate_y_max}, x_max_coordinate : 350+ structureWidth, onEditProvider :onEditProvider, onRemoveProvider:onRemoveProvider},
          position: { x: 25, y: 30 },
          parentId: `${i}`,
          draggable: false,
          extent: 'parent',
          style: { backgroundColor: 'rgb(204, 213, 215, 1)', zIndex : -1},
          type: 'titleNode'
        },
      
        {
          id: `${i}.0`,
          draggable: false,
          position: { x: 350, y: provider.provider_coordinate_y_min},
          className: 'light',
          style: { backgroundColor: 'rgb(204, 213, 215, 1)', borderRadius: 0, width: structureWidth, height: provider.provider_dimension_h, zIndex : -1},
          type: 'group',
        }
      )
      i = i + 1;
    }
    groupProviders.push(
      {
        id: "buttonProviderNode",
        draggable: false,
        position: { x: 150, y: addProviderButtonNodeYCoordinate},
        className: 'light',
        style: { backgroundColor: 'rgb(204, 213, 215, 1)', borderRadius: 0, width: 200, height: 100, zIndex : -1},
        type: 'group',
      },
      {
        id: 'buttonProviderNode-1',
        data: { label: '', onAddNode: handleOpenProviderDialog, buttonCollor: 'rgb(96, 96, 96, 1)', tooltipLabel : t("pages.map_page.add_provider") },
        position: { x: 75, y: 20 },
        className: 'light',
        parentId: "buttonProviderNode",
        extent: 'parent',
        type: 'buttonNode',
        style: {zIndex : -1},
        draggable: false
      },
      {
        id: `${i}.0`,
        draggable: false,
        position: { x: 350, y: addProviderButtonNodeYCoordinate },
        className: 'light',
        style: { backgroundColor: 'rgb(204, 213, 215, 1)', borderRadius: 0, width: structureWidth, height: 100, zIndex : -1},
        type: 'group', 
      }
    )
    return groupProviders;
  }

  const generateOperationNodes = (operationList) =>{ 
    let operationNodes = []
    for (let o of operationList){
      operationNodes.push({
          id: o.id,
          data: { label: o.ops_label,type:  o.shape, width :o.ops_dimension_w, height: o.ops_dimension_h, operation_in_waiting_room : o.operation_in_waiting_room },
          position: { x: o.ops_coordinate_x, y: o.ops_coordinate_y },
          className: 'light',
          type: getShapeType (o.shape),
          style: {},
          draggable: true
      })
    }
    return operationNodes
  }

  
  const generateOperationEdges = (operationList) =>{ 
    let operationEdges = []
    for (let o of operationList){
      operationEdges.push({
          ...o,
          data: { label: o.opsrel_text , type : 'map'},
      })
    }
    return operationEdges
  }
  
  const getWaitingRoom = (structureWidth = 1000) =>{
    return     [{
      id: "waitingRoom",
      data: {  type:  'structure' },
      draggable : false,
      position: { x: 0, y: -100 },
      className: 'light',
      style: { backgroundColor: 'rgb(204, 213, 215, 1)', borderRadius : 0, width: structureWidth, height: 200, zIndex : -1},
      type: 'group',
    },
    {
      id: "waitingRoomTitle",
      data: {label:  t("pages.map_page.waiting_room"), type: 'structure' },
      position: { x: 5, y: 155 },
      parentId: "waitingRoom",
      draggable: false,
      extent: 'parent',
      style: { backgroundColor: 'rgb(204, 213, 215, 1)', zIndex : -1},
      type: 'titleNode'
    },
  
  ]
  }

  const getShapeType = (shapeType) =>{
    switch (shapeType){
      case 'activity_shape':
        return  'activityShape'
      case 'decision_shape':
        return  'decisionShape'
      case 'start_shape':
        return  'startShape'
      case 'end_shape':
        return  'endShape'
      case 'comment_shape':
        return  'commentShape'
    }
  }
  const checkIfNodeIsInEditableRectangle = (editableRectanhgleArea, node) =>{
    let x = node.position.x
    let y = node.position.y
    return editableRectanhgleArea.x_min < x && x <editableRectanhgleArea.x_max && editableRectanhgleArea.y_min < y  && y < editableRectanhgleArea.y_max
  }

  const getProvider = (providersNodes, node, structureWidth) => {
    let y = node.position.y
    let x = node.position.x
    for (let provider of providersNodes){
        if (provider.data.y_coordinates.y_min <= y && y <provider.data.y_coordinates.y_max && x < structureWidth+350){
          return provider
        }
    }
    return undefined
  }

  const checkIfNodeIsInWatinigRoom = (structureWidth, node) => {
    let x = node.position.x
    let y = node.position.y

    return 0 < x && x < structureWidth && -100 < y  && y < 100 
  }


  const calcualteDetaYCoordinate = (YCoordinate, opsProvider) =>{
    return  YCoordinate - opsProvider.data.y_coordinates.y_min
}


  return {
    setDataFromBackend,
    prepareSipocNodeToBackend,
    generateProvidersGroups,
    generateOperationNodes,
    checkIfNodeIsInEditableRectangle,
    getProvider,
    checkIfNodeIsInWatinigRoom,
    prepareOperationNodeToBackend,
    prepareStartEndNodeToBackend,
    getWaitingRoom,
    calcualteDetaYCoordinate,
    generateOperationEdges
  };
}
