import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useTranslation } from "react-i18next";


export default function SipocNodeForm(props) {

  const { t } = useTranslation();


  return (
    <Grid container spacing={1}>
   
      <Grid item xs={12}>
        <TextFieldFormControl
          label={t(`form.sipoc_node_form.kind`)}
          value={t(`form.sipoc_node_form.${props.formValue?.sipocNode}`)}
          disabled={true}
        />
      </Grid>


      <Grid item xs={12}>
        <TextFieldFormControl
          name = "name"
          label={t(`form.sipoc_node_form.name`)}
          value={props.formValue?.name}
          onChange = {props.onChange}
        />
      </Grid>

    </Grid>
  );
};

SipocNodeForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func
};

SipocNodeForm.defaultProps = {
  readOnly: false
};

